import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, MuiThemeProvider, responsiveFontSizes } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

import 'fontsource-overpass';
import 'fontsource-roboto';
import 'fontsource-work-sans';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-WQPWGJT'
}

TagManager.initialize(tagManagerArgs)

const fontFamily = ['overpass', 'work sans'].join(',');

const blueDark = '#11161F';
const blueStd = '#161C24';
const blueLight = '#202730';
const accent = '#cdeaf2';


const theme = responsiveFontSizes(createMuiTheme({
  palette: {
    background: {
      default: grey[50]
    },
    primary: {
      main: blueStd,
      light: blueLight,
      dark: blueDark,
      contrastText: 'white'
    },
    secondary: {
      main: accent,
      contrastText: grey[800]
    },
    text: {
      primary: blueLight,
      secondary: grey[400]
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  typography: {
    fontFamily,
    h1: {
      fontFamily: 'Work Sans',
      fontSize: '3rem',
      letterSpacing: '0.15em',
      color: grey[800],
    },
    h2: {
      fontFamily: 'Work Sans',
      fontSize: '2.25rem',
      letterSpacing: '0.15em',
      color: grey[800],
    },
    h3: {
      fontFamily: 'Work Sans',
      fontSize: '1.9rem',
      fontWeight: 900,
      letterSpacing: '0.07em',
      color: grey[800],
    },
    h4: {
      fontFamily: 'Work Sans',
      fontSize: '1.6rem',
      fontWeight: 900,
      letterSpacing: '0.1em',
      color: grey[800],
    },
    h5: {
      fontFamily: 'Overpass',
      fontSize: '1.4rem',
      fontWeight: 700,
      letterSpacing: '0.05em',
      color: grey[800],
    },
    h6: {
      fontFamily: 'Overpass',
      fontSize: '1.1rem',
      fontWeight: 700,
      color: grey[800],
      letterSpacing: '0.05em',
    },
    body1: {
      fontFamily: 'Overpass',
      fontSize: '1.1rem',
      fontWeight: 300,
      lineHeight: 1.8,
      letterSpacing: '0.02em',
      color: grey[800],
    },
    body2: {
      fontFamily: 'Overpass',
      fontSize: '1rem',
      fontWeight: 300,
      lineHeight: 1.6,
      letterSpacing: '0.01em',
      color: grey[800],
    },
    subtitle1: {
      fontFamily: 'Overpass',
      fontSize: '1.1rem',
      fontWeight: 300,
      color: grey[800]
    },
    subtitle2: {
      fontFamily: 'Overpass',
      fontSize: '1rem',
      color: grey[800]
    },
    button: {
      fontFamily: 'Overpass',
      fontWeight: 600
    }
  },
  shadows: [
    'none', '0 0 15px 0px rgba(0,0,0,0.5), 0 0 50px 0px rgba(0,0,0,0.25)',
    '0 0 10px 0px rgba(0,0,0,0.5), 0 0 50px 0px rgba(0,0,0,0.25)', 'none', 'none',
    'none', 'none', 'none', 'none', 'none',
    'none', 'none', 'none', 'none', 'none',
    'none', 'none', 'none', 'none', 'none',
    'none', 'none', 'none', 'none', 'none'
  ],
  shape: {
    borderRadius: 0
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'antialiased',
        },
      },
    },
  },
}), { factor: 4 });

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline>
      <App />
    </CssBaseline>
  </MuiThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
