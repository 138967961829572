import * as React from 'react';
import { makeStyles, Theme, createStyles, Toolbar, AppBar } from '@material-ui/core';
import logo from '../images/Itellect_Simon JV_white.png';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appbarHide: {
            backgroundColor: 'none',
            boxShadow: 'none',
            transform: 'translateY(-110%)',
            transition: 'transform .3s',
        },
        appbarShow: {
            backgroundColor: theme.palette.primary.dark,
            boxShadow: theme.shadows[2],
            transform: 'translateY(0)',
            transition: 'transform .3s',
        },
        paper: {
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 30,
            paddingRight: 30,
            color: theme.palette.primary.contrastText,
        },
        toolbar: {
            height: 80,
            alignItems: 'center',
        }
    }),
);

const Header: React.FC = () => {
    const classes: { [key: string]: any } = useStyles();

    const [background, setBackground] = React.useState('appbarHide');

    const navRef = React.useRef<string>();
    navRef.current = background;

    React.useEffect(() => {
        const handleScroll = () => {
            const show = window.scrollY > 200
            if (show) {
                setBackground('appbarShow')
            } else {
                setBackground('appbarHide')
            }
        }
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, []);

    return (
        <AppBar className={classes[navRef.current]}>
            <Toolbar className={classes.toolbar}>
                <img src={logo} alt="ITellect Simon JV Logo" height='75%' />
            </Toolbar>
        </AppBar>
    )
};

export default Header;