import React from 'react';

import Main from './contract/Main';
import Header from './common/Header';
import Footer from './common/Footer';

const App: React.SFC = () => {
  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  );
}

export default App;
