import * as React from 'react';

import { Typography, Paper, makeStyles, Theme, createStyles, Container, Grid, Link } from '@material-ui/core';
import bg from '../images/cube-vec.png';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'relative',
            boxShadow: theme.shadows[1],
            background: theme.palette.grey[300],
            color: theme.palette.primary.light,
        },
        bg: {
            position: 'absolute',
            opacity: '0.04',
            zIndex: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        },
        container: {
            maxWidth: theme.breakpoints.values.lg,
            padding: 'calc(30px + 3%) 30px calc(30px + 3%) 30px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
        },
        cards: {
            marginTop: 'calc(20px + 2%)',
        },
        card: {
            boxShadow: 'none',
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            borderTop: '1px solid',
            borderColor: theme.palette.grey[300],
        },
        title: {
            textAlign: 'center',
            padding: 10
        },
        text: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 30,
            [theme.breakpoints.up('md')]: {
                height: 140
            }
        }
    }),
);

const Information: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.paper}>
            <img src={bg} alt='seamless circuit background' className={classes.bg} draggable="false"/>
            <Container className={classes.container}>
                <Typography variant="h4" color='inherit' align='center'>CONTRACT INFORMATION</Typography>
                <Grid container spacing={3} justify="center" alignItems='center' className={classes.cards}>
                    <Grid item xs={10} sm={8} md={4}>
                        <Paper className={classes.card}>
                            <div style={{backgroundColor: 'rgba(255, 255, 255, 0.5)'}}>
                                <Typography variant="h6" color='textPrimary' className={classes.title}>
                                    CONTACT DETAILS
                                </Typography>
                            </div>
                            <Container className={classes.text}>
                                <Typography variant="body1" align="center">
                                    Contract #: 47QTCB21D0402<br />
                                    DUNS: 117589290<br />
                                    Cage Code: 8NGU9
                                </Typography>
                            </Container>
                        </Paper>
                    </Grid>
                    <Grid item xs={10} sm={8} md={4}>
                        <Paper className={classes.card}>
                            <div style={{backgroundColor: 'rgba(255, 255, 255, 0.5)'}}>
                                <Typography variant="h6" color='textPrimary' className={classes.title}>
                                    PROGRAM MANAGER
                                </Typography>
                            </div>
                            <Container className={classes.text}>
                                <Typography variant="body1" align="center">
                                    Harry Wimbish<br />
                                    hwimbish@itellectsimonjv.com<br />
                                    (202) 559-9224
                                </Typography>
                            </Container>

                        </Paper>
                    </Grid>
                    <Grid item xs={10} sm={8} md={4}>
                        <Paper className={classes.card}>
                            <div style={{backgroundColor: 'rgba(255, 255, 255, 0.5)'}}>
                                <Typography variant="h6" color='textPrimary' className={classes.title}>
                                    USEFUL LINKS
                                </Typography>
                            </div>
                            <Container className={classes.text}>
                                <Link
                                    href='https://www.gsa.gov/s3'
                                    variant="body1"
                                    align="center"
                                >
                                    GSA 8(a) STARS III
                                </Link>
                            </Container>

                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
};

export default Information;