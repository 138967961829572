import * as React from 'react';

import { Typography, makeStyles, Theme, createStyles, Container, Divider } from '@material-ui/core';
import bg from '../images/circuit-vec.png';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: theme.palette.primary.light,
            color: theme.palette.grey[100],
        },
        bg: {
            position: 'absolute',
            opacity: '0.075',
            zIndex: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        },
        container: {
            maxWidth: theme.breakpoints.values.lg - 200,
            padding: 'calc(60px + 4%) 30px calc(60px + 4%) 30px',
            zIndex: 1,
        },
        break: {
            paddingTop: 'calc(60px + 4%)'
        },
        line: {
            position: 'relative',
            background: theme.palette.secondary.main,
            height: 5,
            margin: '12px 0 calc(20px + 3%) 0',
        }
    }),
);

const Services: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.paper}>
            <img src={bg} alt='seamless circuit background' className={classes.bg} draggable="false" />
            <Container maxWidth='lg' className={classes.container}>


                <Typography variant="h5" color='inherit'>ABOUT THE JOINT VENTURE</Typography>
                <Divider className={classes.line} />
                <Typography variant="body1" color='textSecondary'>
                    ITellect Simon JV, LLC was formed to provide the government a comprehensive suite of IT services,
                    drawing upon ITellect’s Program Management, Cyber Security and Infrastructure expertise as well
                    as SimonComputing’s Agile Software Development, Cloud Migration and Large System Modernization experience.
                    <br /><br />
                    Through this partnership of complementary expertise, the ITellect Simon JV can tackle the most
                    complex and challenging IT problems the government needs to solve.
                    <br /><br />
                    ITellect Simon JV, LLC is an SBA approved, 8(a) Joint Venture.
                </Typography>


                <Typography variant="h5" color='inherit' className={classes.break}>OUR CLIENTS</Typography>
                <Divider className={classes.line} />
                <Typography variant="h6" color='inherit'>Department of Homeland Security</Typography><br />
                <Typography variant="body1" color='textSecondary'>
                    We have successfully modernized TECS and SEACATS (Seized Assets and
                    Case Tracking System), two of the largest systems at Customs and Border Protection.
                    We have also performed work at TSA, ICE and CIS.
                </Typography><br />
                <Typography variant="h6" color='inherit'>U.S. Department of Defense</Typography><br />
                <Typography variant="body1" color='textSecondary'>
                    We are a leading information technology services firm that delivers business and
                    network engineering solutions to the U.S. Department of Defense and state governments.
                    We specialize in systems engineering, cyber engineering, program management,
                    business performance management, and financial management support.
                </Typography>


                <Typography variant="h5" color='inherit' className={classes.break}>OUR SERVICES</Typography>
                <Divider className={classes.line} />
                <Typography variant="h6" color='inherit'>Program Management</Typography><br />
                <Typography variant="body1" color='textSecondary'>
                    Today’s government program offices must balance growing mission requirements with budget realities.
                    We leverage our vast program management expertise and the latest tools and techniques to optimize
                    the management and execution of programs across government.
                    It's all about helping you make better decisions for better outcomes.
                </Typography><br /><br />
                <Typography variant="h6" color='inherit'>Bespoke Software Development</Typography><br />
                <Typography variant="body1" color='textSecondary'>
                    Whether you’re a government agency or an entrepreneur with a great idea, we can build a quality solution to meet your needs.
                    We specialize in building web and mobile applications utilizing the latest open source technologies.
                    We build with a CI/CD pipeline from the start so that your project benefits from automated testing,
                    security scans and code quality scans.
                </Typography><br /><br />
                <Typography variant="h6" color='inherit'>Cloud Transformation</Typography><br />
                <Typography variant="body1" color='textSecondary'>
                    ITellect Simon has 16+ years of modernization experience with the last 7 years involved in Cloud Transformation projects.
                    Every facet of our approach has been trimmed down and simplified so that our team communicates
                    key information effectively while maximizing the time spent on delivering high quality
                </Typography><br /><br />
                <Typography variant="h6" color='inherit'>Systems Engineering &amp; Integration (SE&amp;I)</Typography><br />
                <Typography variant="body1" color='textSecondary'>
                    We utilize a holistic DoD and industry-standards-based approach to developing innovative systems engineering and integration solutions.
                    By applying rigor and discipline to Systems Requirements and Management, Functional Analysis and Design,
                    and Synthesis and Integration of architectural concepts, we consistently deliver reliable, high-quality,
                    and cost-effective systems to the military and commercial sectors.
                </Typography><br /><br />
                <Typography variant="h6" color='inherit'>Cyber Security &amp; Information Assurance</Typography><br />
                <Typography variant="body1" color='textSecondary'>
                    Cyber security and information assurance is a top priority for all our government customers.
                    Our team leads customers through the development and execution of critical IT security best practices
                    such as information assurance, implementing IT security processes and tools,
                    and managing Security Operations Centers (SOC) and Network Operation Centers (NOC) across the enterprise.
                </Typography><br /><br />
                <Typography variant="h6" color='inherit'>Business Performance Management &amp; Optimization</Typography><br />
                <Typography variant="body1" color='textSecondary'>
                    ITellect Simon empowers government customers to navigate transformation challenges for a
                    better business impact by aligning personnel and processes to optimize performance and do more with less.
                    Our proven experience, perspective, and insights help us effectively guide our customers through
                    the complexities of budget pressures, business management, and transformational change.
                </Typography><br /><br />
                <Typography variant="h6" color='inherit'>Contract &amp; Financial Support</Typography><br />
                <Typography variant="body1" color='textSecondary'>
                    A deep understanding of the nuances of the government acquisition process, allows our team to
                    provide agile support that delivers the right product, service or solution to meet mission needs.
                    ITellect's team of acquisition specialists are backed by project manager experts who have hands-on subject matter expertise in acquisition.
                    When you engage with ITellect Simon, you can rest assured that you get decisions backed by the experience of the industry's best.
                </Typography><br />
            </Container>
        </div>
    )
};

export default Services;