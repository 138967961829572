import * as React from 'react';

import Banner from './Banner';
import Overview from './Information';
import Program from './Program';
import Services from './Services';

const Main: React.FC = () => {
    return (
        <>
            <Banner />
            <Overview />
            <Program />
            <Services />
        </>
    )
};

export default Main;
