import * as React from 'react';

import { Typography, makeStyles, Theme, createStyles } from '@material-ui/core';

import bg from '../images/architecture.jpg';
import logo from '../images/Itellect_Simon JV_white.png';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'relative',
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
        },
        bg: {
            position: 'absolute',
            opacity: '0.2',
            filter: 'contrast(50%) saturate(150%)',
            width: '100%',
            height: '100%',
            minHeight: 300,
            objectFit: 'cover',
            transform: 'translateZ(0px)',
        },
        fg: {
            [theme.breakpoints.down('sm')]: {
                padding: '80px 10px 80px 10px',
            },
            [theme.breakpoints.up('md')]: {
                padding: '150px 10px 150px 10px',
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            color: theme.palette.grey[100],
            transformOrigin: 0,
            transform: 'translateZ(0px)',
        },
        logo: {
            margin: 'auto',
            width: '80%',
            maxWidth: 800,
            paddingBottom: 'calc(30px + 3%)'
        },
        sub: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.9rem',
                letterSpacing: '0.1rem',
            },
            [theme.breakpoints.up('md')]: {
                letterSpacing: '0.3rem',
            },
        }
    }),
);

const Banner: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.paper}>
            <img src={bg} alt='background' className={classes.bg} />

            <div className={classes.fg}>
                <img src={logo} alt="ITellect Simon JV Logo" className={classes.logo}/>
                <Typography variant='h2' color='inherit' align='center'>
                    GSA 8(a) STARS III
                </Typography>
                <Typography variant='subtitle1' color='textSecondary' align='center' className={classes.sub}>
                    CONTRACT: 47QTCB21D0402
                </Typography>
            </div>

        </div>
    )
};

export default Banner;