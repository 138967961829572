import * as React from 'react';
import { Typography, Paper, makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            height: 100,
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText
        },
    }),
);

const Footer: React.FC = () => {
    const classes = useStyles();

    return (
        <Paper square className={classes.paper}>
            <Typography variant="body1" align='center' color='inherit'>ITellect Simon JV, LLC{<br />}@ Copyright 2022</Typography>
        </Paper>
    )
};

export default Footer;
