import * as React from 'react';

import { Typography, makeStyles, Theme, createStyles, Box, Container, Divider, Hidden } from '@material-ui/core';
import { FormatQuoteSharp } from '@material-ui/icons';

import gsa from '../images/gsa8astarsiii.png';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: theme.palette.grey[100],
            borderTop: '1px solid',
            borderColor: theme.palette.grey[300],
        },
        container: {
            margin: 'auto',
            padding: '80px 20px 80px 20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.primary.light,
            maxWidth: theme.breakpoints.values.lg - 200
        },
        title: {
            margin: 'auto',
            marginBottom: 'calc(20px + 2%)'
        },
        banner: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 30,
            width: '100%'
        },
        logo: {
            width: 300,
            objectFit: 'cover',
            paddingBottom: 10,
        },
        text: {
            fontWeight: 400,
            letterSpacing: '0.05rem',
            color: theme.palette.primary.main
        },
        quoteL: {
            fontSize: '1.5rem',
            transform: 'rotate(180deg)',
            margin: 30,
            marginBottom: 40,
        },
        quoteR: {
            fontSize: '1.5rem',
            margin: 30,
            marginBottom: 40,
        },
        details: {
            maxWidth: theme.breakpoints.values.md - 220,
            marginTop: 60
        },
        div: {
            background: theme.palette.grey[300],
            height: 1,
            width: '100%',
        }

    }),
);

const Program: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.paper}>
            <div className={classes.container}>
                <Box color='inherit' display='flex' flexDirection="column" justifyContent="center" alignItems="center">
                    <Typography variant="h4" color='inherit' className={classes.title}>PROGRAM DETAILS</Typography>
                    <Box display='flex' flexDirection="column" alignItems='center' justifyContent='center' style={{ margin: 'auto auto 50px auto' }}>
                        <img src={gsa} alt='gsa logo' className={classes.logo} />
                        <Typography variant='h6' className={classes.text}>Contract: 47QTCB21D0402</Typography>
                    </Box>
                    <Divider className={classes.div} />
                    <Box my={4} display='flex' flexDirection="column" justifyContent="center" alignItems="center">
                        <Box display='flex' flexDirection="row" justifyContent="center" alignItems="center">
                            <Hidden smDown>
                                <FormatQuoteSharp className={classes.quoteL} />
                            </Hidden>
                            <Typography variant="body1" align='center'>
                                8(a) STARS III is a small business set-aside Government Wide Acquisition Contract (GWAC),
                                will provide flexible access to customized IT solutions from a large, diverse pool of 8(a)
                                industry partners. This next-generation GWAC will build upon the framework of 8(a) STARS II
                                and expand capabilities for emerging technologies and OCONUS requirements.
                            </Typography>
                            <Hidden smDown>
                                <FormatQuoteSharp className={classes.quoteR} />
                            </Hidden>
                        </Box>
                        <Typography variant="body1">⁠— www.gsa.gov/s3</Typography>
                    </Box>
                    <Divider className={classes.div} />
                    <Container className={classes.details}>
                        <Typography variant="h6" color='inherit' align='center'>Features of STARS III:</Typography>
                        <Typography variant="body1">
                            <br />
                            <li>$50 billion program ceiling</li>
                            <li>Five year base period plus one, three year option</li>
                            <li>Task orders can extend 5 years beyond last ordering day</li>
                            <li>Task order types: fixed price, labor hour, and time and material terms</li>
                            <li>CONUS/OCONUS services</li>
                            <li>Emerging Technology scope area</li>
                            <li>Directed Order Authority: task orders up to $4.5 million each</li>
                        </Typography><br /><br />
                        <Typography variant="h6" color='inherit' align='center'>Benefits of STARS III:</Typography>
                        <Typography variant="body1">
                            <br />
                            <li>Enables federal clients to earn 8(a) and Small Business credit</li>
                            <li>Pre-competed, easy-to-use contracts</li>
                            <li>Access to proven 8(a) small business technology providers</li>
                            <li>Sub Pool of pre-selected 8(a) firms with deep Emerging Technology capabilities</li>
                            <li>Short procurement lead time</li>
                            <li>A low user access fee (0.75%) built into contractor’s ceiling price</li>
                            <li>Limited protest ability in accordance with NDAA 2008</li>
                            <li>Sole source awards up to $4.5 million ceiling</li>
                        </Typography>
                    </Container>
                </Box>
            </div>
        </div>
    )
};

export default Program;